const zh_cn = {
    /**
     * 校验按钮国际化
     * @author binhg
     */
    mloan_msg_btn_text: '获取验证码',
    mloan_msg_during_text_pre: '需等待',
    mloan_msg_during_text_last: '秒',

    /**
     * @desc login
     * @author yt
     */
    mloan_login_alert_hint: '温馨提示',
    mloan_login_alert_message: '您的登录信息有误,服务端信息',
    mloan_login_loading: '正在登陆...',
    mloan_login_success: '登录成功,点击确认返回',
    mloan_login_alert_error_message: '您填写的信息还不完善或格式有误，请检查后再提交',
    mloan_login_no_success: '您还未完成登陆,点击确认将返回首页',
    mloan_login_product_detail: '产品详情页面继续申请操作',
    mloan_login_loan_list: '贷款进度列表',

    // 全局接口返回异常提示
    app_login_err_msg: '您还未登陆或登陆已超时,点击确定跳转至登陆界面',
    app_server_err_msg: '服务端出现异常,请稍后重试或通知本行客服.服务端返回信息',

}

export default zh_cn
