const zh_hk = {
    /**
     * 校验按钮国际化
     * @author binhg
     */
    mloan_msg_btn_text: '獲取驗證碼',
    mloan_msg_during_text_pre: '需等待',
    mloan_msg_during_text_last: '秒',

    /**
     * @desc login
     * @author yt
     */
    mloan_login_alert_hint: '温馨提示',
    mloan_login_alert_message: '您的登錄信息有誤,服務端信息',
    mloan_login_loading: '正在登陸...',
    mloan_login_success: '登錄成功,點擊確認返回',
    mloan_login_alert_error_message: '您填寫的信息還不完善或格式有誤，請檢查後再提交',
    mloan_login_no_success: '您還未完成登陸,點擊確認將返回首頁',
    mloan_login_product_detail: '產品詳情頁面繼續申請操作',
    mloan_login_loan_list: '貸款進度列表',

    /**
     * 全局接口返回异常提示
     * @author binhg
     */
    app_login_err_msg: '您還未登陸或登陸已超時,點擊確定跳轉到登錄界面',
    app_server_err_msg: '服務端出現異常,請稍後重新嘗試或聯繫本行管理員.服務端返回信息:',
}
export default zh_hk
