/**
 * @author binhg
 * @desc 根reducers
 */

import { combineReducers } from 'redux';
import { demo } from './Version/Version.reducer';

export const reducers = combineReducers({
	demo
});
