import { postUrl } from 'wl-fetch';
import { WlAction, WlDispatch } from '../../../../types/WlActions';
import { LOAD_METRICS } from '../../../Config/Api/Demo.path';

const LOAD_AND_SAVE_METRICS = 'LOAD_AND_SAVE_METRICS';
const CLEAR_METRICS_DATA = 'CLEAR_METRICS_DATA';

const initialState = {
	metricList: [] // 声明书详情数据
};

export function metric(state = initialState, action: WlAction) {
	switch (action.type) {
		case LOAD_AND_SAVE_METRICS:
			state.metricList = action.payload;
			return state;
		case CLEAR_METRICS_DATA: // 在列表中清空详情的数据
			state.metricList = [];
			return state;
		default:
			return state;
	}
}

/**
 * 清空xxx
 */
export function clearMetrics(dispatch: WlDispatch) {
	dispatch({ type: CLEAR_METRICS_DATA, payload: undefined });
}

/**
 * 仅做接口请求
 */
export function doSomethins(params: any) {
	return (dispatch: WlDispatch) =>
		new Promise((resolve, reject) => {
			postUrl('remote url', params)
				.then((data: any) => {
					resolve(data);
				})
				.catch((err: any) => {
					reject(err);
				});
		});
}

export function loadMetricsData(params: any) {
	return (dispatch: WlDispatch) =>
		new Promise((resolve, reject) => {
			postUrl(LOAD_METRICS, params)
				.then((data: any) => {
					dispatch({ type: LOAD_AND_SAVE_METRICS, payload: data.data });
					resolve(data);
				})
				.catch((err: any) => {
					reject(err);
				});
		});
}
