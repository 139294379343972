import { createGlobalStyle } from 'styled-components';
import { animateStyle } from './Animate.style';
import { antmStyle } from './Antm.style';

const resetStyle = `
html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
`;

export const { clientWidth, clientHeight } = window.document.documentElement;

/**
 * @description 获取到机器Rem值
 */
export function getRemValue() {
	return 100 * (document.documentElement.clientWidth / 750);
}

/**
 * 获取到容器宽高
 */
export function getScreen() {
	return document.documentElement;
}

export function bindRecalcRem() {
	let docEl = document.documentElement,
		resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
		recalc = function(event: any) {
			let currentTarget = event.target,
				type = event.type;
			if (type === 'resize' && currentTarget !== window) return;
			var clientWidth = docEl.clientWidth;
			docEl.style.fontSize = 100 * (clientWidth / 750) + 'px';
		};
	window.addEventListener(resizeEvt, recalc, false);
	document.addEventListener('DOMContentLoaded', recalc, false);
}

export const GlobalStyles = createGlobalStyle`
    ${resetStyle}

    html{
        font-size:${getRemValue()}px;
    }

    #root{
        font-size:0.14rem;
        // min-width:${clientWidth}px;
        // min-height:${clientHeight}px;
    }

    ${antmStyle}
    ${animateStyle}
`;
