import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getComponentLazy } from '../Components/Bundle';
// import Demo2Routes from './Demo2/Demo2.routes';
import VersionUpdate from './Version/Pages/VersionUpdate/VersionUpdate';
// const CounterRoutes = getComponentLazy(() => import(/* webpackChunkName:"demo" */ './Version/Version.routes')); //贷款计算器路由
// 
interface DemoRouteProps {
	match: any;
}
export default class Routes extends Component<DemoRouteProps> {
	constructor(props: DemoRouteProps) {
		super(props);
	}

	public render() {
		console.log(8888);
		const { url } = this.props.match;
		return (
			<Switch>
				<Route path={`${url}/versionUpdate`} component={VersionUpdate} />
				{/* <Route path={`${url}/demo2`} component={Demo2Routes} /> */}
			</Switch>
		);
	}
}
