/**
 * @des 立橋銀行APP版本升级
 * @author Helen  
 */
import React, { Component } from 'react';
import styled from 'styled-components';
import { clientHeight, clientWidth, GlobalStyles } from '../../../Common/Base/Base.style'
import { NavBar, Button, Toast } from 'wl-design-mobile';
import { RouterProps } from '../../../../../types/RouterProps';
import { initState } from '../../../../../types/State';

export default class VersionUpdate extends Component<RouterProps, initState>{
    constructor(props: RouterProps) {
        super(props)
        this.state = {
            inited: false,
            searchParam: {
                devType: '',
                downLink: '',
                upDate: ''
            },
        }
    }

    componentDidMount() {
        this.getLocationSearch()
    }
    getLocationSearch() {
        const search = window.location.hash || ''; // window.location.hash;
        console.log(search);
        if (search.includes('?')) {
            const searchArr = search.split('?')[1].split('&');
            let paramObj: any = {};
            searchArr.forEach(item => {
                let arr = item.split('=');
                paramObj[arr[0]] = arr[1]
            })
            this.setState({
                searchParam: paramObj,
            })

        }

        this.setState({
            inited: true
        })


    }
    goDownloadLink() {
        const { searchParam: { devType, downLink } } = this.state;
        if (!downLink || downLink === 'null' || downLink === "undefined") {
            Toast.fail('无效的下载地址')
        } else {
            window.location.assign(downLink);
        }
    }
    renderAndroidBtn() {
        return (
            <Button className="btn" onClick={() => this.goDownloadLink()} >
                <img src={require('../../../Common/Images/android_icon@2x.png')} alt={'icon_android'} />
                <span>Android下载</span>
            </Button>
        )
    }
    renderIosBtn() {
        return (
            <Button onClick={() => this.goDownloadLink()} className="btn">
                <img src={require('../../../Common/Images/ios_icon@2x.png')} alt='icon_ios' />
                <span>App Store下载</span>
            </Button>
        )
    }
    render() {
        const { inited, searchParam: { devType, downLink, upDate } } = this.state;
        return (
            <VersionUpContainer>
                <GlobalStyles />
                <div className="headBox">
                    <NavBar
                        mode="light"
                        className=""
                    >
                        <span className='nav-title' style={{ color: '#FFF' }}>立桥银行App升级</span>
                    </NavBar>
                </div>
                <div style={{ flex: 0.5 }}>
                    {devType ?
                        <div className="buttonBox">
                            {devType === 'android' ?
                                this.renderAndroidBtn() :
                                this.renderIosBtn()}

                        </div> :
                        <div className="buttonBox">
                            {this.renderAndroidBtn()}
                            {this.renderIosBtn()}
                        </div>
                    }
                    <p className="upTime">版本更新时间：{upDate}</p>
                </div>
                <p className="note">
                    {/* 说明文案说明文案说明文案说明aaasf we文案说明文案说明文案fds f sfwe明文案说明文fds fw案说明文案说明文案说明文案说明文案说明文案 */}
                </p>
            </VersionUpContainer>
        )
    }
}

const VersionUpContainer = styled.div`
        height: ${clientHeight}px;
      //  display: flex;
       // flex-direction: column;
       // justify-content: space-between;
        background: #fff;
        .headBox {
            height: 5.3rem;
            .am-navbar-light {
                background-color: transparent;
            }
            background: url(${require('../../../Common/Images/headBg@2x.png')}) no-repeat center;
            background-size:100% 100%;
            .imgBg{
                width: 100%;
                height: 5.1rem;
                position: absolute;
                top: 0;
            }
        }
        .buttonBox {
            width: ${clientWidth}px;
            margin-top:1.8rem;
            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width:4rem;
                height:1rem;
                margin:auto;
                margin-bottom:1em;
                border:1px solid rgba(43,127,244,1);
                border-radius:0.2rem;
                font-weight:500;
                color:rgba(43,127,244,1);
                img{
                    margin-right: 0.2rem;
                    max-width: 100%;
                    height: 0.44rem;
                }
                span {
                    font-size:0.3rem;
                    color: #2B7FF4
                }
                a {
                    color: #2B7FF4
                }
            }
            
        }
        .upTime {
            margin-top:1em;
            text-align:center;
            font-size:0.24rem;
            font-weight:500;
            color:rgba(146,146,146,1);
        }
        .note {
            margin:0.3rem;
            margin-bottom:0.2rem;
            line-height:0.4rem;
            text-align: justify;
            font-size:0.24rem;
            font-weight:500;
            color:rgba(146,146,146,1);
        }
`;
