/**
 * @author binhg
 * @description 主页入口
 */

import { createHashHistory, History } from 'history';
import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import App from './app';
// import { bindRecalcRem } from './Modules/Common/Base/Base.style';
import { reducers } from './Modules/Reducers';
import { bindRecalcRem } from './Modules/Common/Base/Base.style';

bindRecalcRem();

const store = createStore(
	reducers,
	process.env.NODE_ENV === 'development' ? composeWithDevTools(applyMiddleware(thunk)) : applyMiddleware(thunk)
);
const history: History = createHashHistory();
ReactDom.render(
	<Provider store={store}>
		<Router history={history}>
			<Switch>
				<Route path="/app/:locale" component={App} />
			</Switch>
		</Router>
	</Provider>,
	document.getElementById('root') as HTMLElement
);
