/**
 * @author binhg
 * @desc Demo reducers
 */

import { combineReducers } from 'redux';
import { metric } from './Store/Version.redux';

export const demo = combineReducers({
	metric
});
