import { ServerPath } from '../Env';

const prefix = `${ServerPath}`;

export const LOGIN_NORMAL = `${prefix}/account/login`;

export const REGISTER_ACCOUNT = `${prefix}/account/register`;

export const QUERY_BOOKS = `${prefix}/book/search`;

export const QUERY_READING_LIST = `${prefix}/book/queryGoodList`;

export const QUERY_GARBAGE_LIST = `${prefix}/book/queryGarbageList`;

export const DROP_TO_GARBAGE = `${prefix}/book/drop2Garbage`;

export const PICK_FROM_GARBAGE = `${prefix}/book/pickFromGarbage`;

export const ADD_BOOKS = `${prefix}/book/add`;

export const LOAD_METRICS = `${prefix}/XXX/XXX`;
