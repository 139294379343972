/**
 * @author binhg
 * @desc 国际化
 */
import en_us from './en_US'
import zh_cn from './zh_CN'
import zh_hk from './zh_HK'

export const Locales = {
	zh_cn,
	zh_hk,
	en_us
}

export const MAP_LOCALE_ANTD = {
	zh_cn: 'zh_CN',
	zh_hk: 'zh_TW',
	en_us: 'en_US'
}

export const SERVER_LOCALE_MAP: {
	[props: string]: string;
} = {
	zh_cn: 'zh_CN',
	zh_hk: 'zh_HK',
	en_us: 'en_US',
	pt_pt: 'pt_PT'
}
