import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { updateGlobalConfig } from 'wl-fetch';
import { intl } from 'wl-intl';
import { Locales, SERVER_LOCALE_MAP } from './Config/Locale';
import { clientHeight, clientWidth, GlobalStyles } from './Modules/Common/Base/Base.style';
import Routes from './Modules/Routes'
import VersionUpdate from './Modules/Version/Pages/VersionUpdate/VersionUpdate';
interface AppProps {
	match: any;
}

interface AppState {
	inited: boolean;
}

class App extends Component<AppProps, AppState> {
	public state: AppState = {
		inited: true
	};
	public componentDidMount() {
		this.init();
	}

	public async init() {
		const { match: { params: { locale } } } = this.props;
		//设置全局网络请求headers
		updateGlobalConfig({
			headers: {
				i18n: SERVER_LOCALE_MAP[locale]
			}
		});
		intl.init({
			currentLocale: locale,
			locales: Locales
		});
		// const dicts = await loadDictInfoMore(dsf_dict_list);
		// this.setState({
		// 	inited: initedLocale && isResultOk(dicts)
		// });
	}

	public render() {
		const { url } = this.props.match;
		return (
			<AppContainer>
				<GlobalStyles />
				<Switch>
					<Route path={`${url}/index`} component={VersionUpdate} />
					<Route path={`${url}`} component={Routes} />
				</Switch>
			</AppContainer>
		);
	}
}

export default connect(null, {})(App);

const AppContainer = styled.div`
	// min-width: ${clientWidth}px;
	// min-height: ${clientHeight}px;
`;
